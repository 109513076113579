.c-error {
    @apply mt-1 font-sans text-xs font-medium tracking-wide text-red-900;

    svg {
        @apply inline-block h-4 w-4;

        + span {
            @apply ml-1;
        }
    }

    span {
        @apply align-middle;
    }

    &__form {
        @apply flex flex-row rounded-lg bg-red-200 p-1;

        svg {
            @apply h-5 w-5 flex-shrink-0;

            + span {
                @apply pt-0.5;
            }
        }
    }
}
