.c-forgot-password {
    @apply rounded-lg bg-white p-4 shadow-md md:p-12;

    &__content {
        @apply mx-auto w-full md:w-1/2;
    }

    &__form {
        @apply my-6;

        &__actions {
            @apply mt-12 flex flex-col items-center gap-y-2 text-center;

            &.submitted {
                @apply hidden;
            }
        }

        &.submitted {
            .c-forgot-password__form__actions {
                @apply block;

                &:not(.submitted) {
                    @apply hidden;
                }
            }
        }

        .c-anchor {
            @apply mt-2 font-semibold tracking-wider text-grey-500 no-underline;
        }

        .c-paragraph {
            @apply mt-2 text-xs font-semibold uppercase tracking-wider text-grey-500 no-underline;

            span {
                @apply cursor-pointer underline;
            }
        }
    }

    &__header {
        @apply text-center md:px-8;

        > * {
            @apply mb-2;
        }

        svg {
            @apply mb-6 inline-block h-12 w-12 text-primary-400;
        }
    }
}
