.c-add-member-page {
    @apply rounded-lg bg-white p-4 shadow-md md:p-12;

    &__header {
        @apply px-2 text-center md:mx-auto md:w-3/4 md:px-8;
    }

    &__section {
        @apply mt-8;

        &.center {
            @apply text-center;
        }

        &__wrapper {
            @apply grid gap-y-4 gap-x-8;

            &.double {
                @apply grid-cols-1 md:grid-cols-2;
            }

            &.triple {
                @apply grid-cols-1 md:grid-cols-3;
            }

            + .c-onboarding-page__section__wrapper {
                @apply mt-4;
            }
        }

        h3 + .c-onboarding-page__section__wrapper {
            @apply mt-4;
        }
    }
}
