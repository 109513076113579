.c-category-search {
    @apply w-full;

    &__item {
        @apply flex cursor-pointer flex-row items-center gap-x-4 rounded-lg bg-primary-700 p-5 text-left;

        .c-paragraph {
            @apply flex-1;
        }

        img {
            @apply h-full;

            max-width: 4.5rem;
        }

        svg {
            @apply inline-block flex-none text-white;

            height: 24px;
            width: 24px;
        }
    }

    &__list {
        @apply grid w-full grid-cols-1 gap-y-5 gap-x-10 py-5 md:grid-cols-3;
    }

    &__wrapper {
        @apply relative mx-auto w-full flex-1 py-9 px-4 text-center md:px-8 lg:px-4;

        max-width: 63.5rem;
    }

    &.primary {
        @apply bg-primary-400;
    }

    .c-button {
        @apply relative md:absolute;

        top: 0.4rem;

        @screen md {
            top: 2.3rem;
            left: 1rem;
        }
    }
}
