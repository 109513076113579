.c-order-list-item {
    &__header {
        @apply flex
            w-full
            justify-between
            rounded-md
            border
            border-[1.5px]
            border-grey-200
            py-4
            px-2;

        &-item {
            @apply whitespace-nowrap
                text-3xs
                font-bold
                uppercase
                tracking-[1.5px]
                text-grey-200;
        }
    }
}
