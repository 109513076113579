.c-personalize-price {
    @apply flex h-full flex-col py-8;

    &__actions {
        @apply mt-1 text-right;
    }

    &__amount.c-paragraph {
        @apply text-4xl-s;
    }

    &__content {
        @apply flex flex-1 flex-col justify-center gap-y-4;

        .c-label {
            @apply text-sm text-grey-200;
        }
    }

    &__timed-out {
        @apply max-w-2xl;
    }

    &__section {
        @apply mt-1 w-full rounded-lg border border-solid border-primary-700 p-5;

        border-width: 1.5px;

        &.primary {
            @apply bg-primary-700;

            .c-personalize-price__section__content {
                > * {
                    @apply text-center;
                }

                .c-paragraph {
                    &.medium {
                        @apply text-base;
                    }

                    &.large {
                        @apply text-3xl;
                    }
                }
            }
        }

        h4 {
            @apply text-grey-500;
        }

        .c-label {
            @apply inline-block text-2xs text-grey-500;

            line-height: 0.75rem;
        }

        .c-paragraph.medium {
            @apply text-l;

            &:not(.white) {
                @apply text-grey-200;
            }

            line-height: 1.625rem;
        }

        &__header {
            @apply mb-5;

            &.no-margin {
                @apply mb-0;
            }
        }

        &__content {
            @apply grid grid-cols-1 gap-y-4 md:grid-cols-4;

            &.one {
                @apply md:grid-cols-1;
            }

            &.two {
                @apply md:grid-cols-2;
            }
        }
    }

    &.processing {
        @apply items-center;

        .c-personalize-price {
            &__content {
                @apply items-center text-center;
            }
        }
    }

    .c-confirm {
        @apply mb-2;
    }

    .c-paragraph + .c-personalize-price__section {
        @apply mt-20;
    }
}
