.c-onboarding-layout {
    @apply relative flex h-screen overflow-hidden;

    &.colored {
        @apply bg-primary-700;

        .c-onboarding-layout__container__footer {
            @apply text-white;

            .c-anchor {
                @apply text-white;
            }

            .c-paragraph.xsmall {
                @apply text-white;
            }
        }
    }

    &__window {
        @apply flex w-0 flex-1 flex-col overflow-y-auto;
    }

    &__content {
        @apply focus:outline-none relative mx-auto w-full flex-1 px-6 md:px-8;

        max-width: 63.5rem;
    }

    &__container {
        @apply px-0 pt-2 md:px-8 lg:px-4;

        &__actions {
            @apply flex flex-row items-center;

            img {
                @apply hidden md:ml-14 md:block lg:ml-6;
            }

            &__logout {
                @apply cursor-pointer;

                .c-label {
                    @apply ml-1 cursor-pointer text-primary-400;

                    line-height: 1.5rem;
                }

                svg {
                    @apply inline-block h-6 w-6 text-primary-400;
                }
            }
        }

        &__header {
            @apply cursor-pointer bg-primary-700 py-5;

            &__content {
                @apply flex flex-row justify-between px-0 md:px-8 lg:px-4;
            }

            img {
                height: 2rem;
                width: auto;

                + img {
                    height: 1.5rem;
                }
            }
        }

        &__footer {
            @apply flex flex-col items-center justify-between gap-4 py-6 text-3xs text-grey-200 text-white md:flex-row;

            & > * {
                @apply flex-none;
            }

            &__logo {
                @apply h-full;
            }

            &__links {
                flex-grow: 2;
            }

            .c-anchor {
                @apply px-1 text-3xs font-bold tracking-widest text-grey-200;
            }

            .c-paragraph.xsmall {
                @apply text-right font-semibold tracking-widest text-grey-200;
            }

            img {
                height: 1.25rem;
                width: auto;

                @screen md {
                    height: 1.5rem;
                }
            }
        }
    }
}
