.c-profile {
    @apply my-10;

    &__header {
        @apply mt-7;

        h1 {
            @apply mt-7;
        }
    }

    &__intro {
        @apply flex flex-row items-start gap-x-4;

        .c-button {
            @apply flex-none;
        }
    }

    &__section {
        @apply mt-8;

        &.center {
            @apply text-center;
        }

        &__wrapper {
            @apply grid gap-y-4 gap-x-8;

            &.double {
                @apply grid-cols-1 md:grid-cols-2;
            }

            &.triple {
                @apply grid-cols-1 md:grid-cols-3;
            }

            + .c-profile__section__wrapper {
                @apply mt-4;
            }
        }

        h3 + .c-profile__section__wrapper {
            @apply mt-4;
        }
    }
}
