.c-personalize-consent {
    @apply rounded-lg border border-solid border-primary-999 px-7 py-6;

    border-width: 1.5px;
    //max-width: 37rem;
}

.c-member-form {
    .c-personalize-consent {
        h3 {
            @apply text-primary-700;
        }

        .c-checkbox {
            @apply mt-10;

            &__label {
                @apply text-primary-700;
            }
        }
    }
    &__section__wrapper {
        @apply mb-6;
    }
}
