.c-checkbox {
    @apply flex cursor-pointer flex-row items-center font-sans uppercase tracking-widest text-grey-500;

    input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &__checkmark {
        @apply inline-block flex-none rounded-lg border-2 border-solid border-primary-700 bg-white;

        height: 30px;
        width: 30px;

        svg {
            @apply text-transparent transition-all;
        }

        &.active {
            svg {
                @apply text-primary-700;
            }
        }

        &.locked {
            @apply border-grey-200;

            &.active {
                svg {
                    @apply text-grey-200;
                }
            }
        }
    }

    &__label {
        @apply mx-2.5 text-sm font-semibold tracking-wider text-grey-200;
    }

    &.white {
        .c-checkbox {
            &__checkmark {
                @apply border-white bg-transparent;

                &.active {
                    svg {
                        @apply text-white;
                    }
                }
            }

            &__label {
                @apply text-white;
            }
        }
    }
}
