.c-modal {
    @apply fixed inset-0 z-10 overflow-y-auto;

    &__actions {
        @apply mt-6 text-right;

        .c-button {
            @apply block w-full;

            + .c-button {
                @apply mt-5;
            }
        }
    }

    &__content {
        @apply flex min-h-screen items-center justify-center py-8 px-0 text-center sm:block sm:p-0 md:px-4;
    }

    &__header {
        @apply mb-2 inline-block w-full text-center;
    }

    &__overlay {
        @apply fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity;
    }

    &__wrapper {
        @apply inline-block transform rounded-lg bg-white px-4 py-8 text-left align-bottom shadow-xl transition-all sm:align-middle md:p-12;

        min-width: 28rem;

        &.-delete {
            @apply sm:w-1/4;
        }

        &.full {
            @apply w-full md:w-3/4;

            max-width: 52.625rem;

            .c-modal__actions {
                @apply text-center;

                .c-button {
                    @apply mx-auto w-auto md:inline-block;

                    + .c-button {
                        @apply md:mt-0 md:ml-4;
                    }
                }
            }
        }
    }
}
