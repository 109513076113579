.c-personalize-pay {
    @apply flex h-full flex-col pb-8;

    &__amount.c-paragraph {
        @apply text-4xl-s;
    }

    &__content {
        @apply flex flex-1 flex-col justify-center gap-y-4;
    }

    &__section {
        @apply mt-1 w-full rounded-lg border border-solid border-grey-200 p-5;

        border-width: 1.5px;

        &.primary {
            @apply border-primary-700 bg-primary-700;
        }

        .c-label {
            @apply inline-block;
        }

        &__header {
            @apply mb-5;

            &.no-margin {
                @apply mb-0;
            }
        }

        &__content {
            @apply grid grid-cols-1 gap-x-10 gap-y-4 md:grid-cols-1;

            &__item {
                @apply flex flex-col items-center justify-center gap-1;

                > * {
                    @apply flex w-full flex-row items-center justify-between;
                }

                &.between {
                    @apply justify-between;
                }
            }

            &.two {
                @apply md:grid-cols-2;
            }

            h4 {
                @apply text-white;
            }
        }
    }

    &__pay-now {
        @apply flex flex-col items-center gap-x-10 gap-y-4 md:flex-row md:items-start;

        &__discount {
            @apply text-xs uppercase;
        }

        &__intro {
            @apply flex-1;

            .c-paragraph {
                @apply mt-1;

                + .c-paragraph {
                    @apply mt-4 tracking-wider;
                }
            }
        }
    }

    &__pay-later {
        @apply flex flex-col items-center gap-x-10 gap-y-4;

        &__intro {
            @apply w-full flex-1;

            .c-paragraph {
                @apply mt-1;

                + .c-paragraph {
                    @apply mt-4 tracking-wider;
                }
            }
        }

        &__details {
            @apply flex w-full flex-col gap-x-8 gap-y-4 sm:flex-row;
        }

        &__term-selection {
            @apply block flex-grow rounded-lg border border-solid border-grey-200 p-8 text-center;

            .c-label.medium.light {
                @apply block text-grey-200;
            }

            &__slider {
                @apply relative my-3;
            }

            &__label {
                @apply absolute bottom-full mb-2 inline-flex w-28 items-center justify-center whitespace-nowrap rounded-md bg-primary-700 py-2 text-xs font-semibold uppercase text-white;

                &__arrow-down {
                    @apply absolute top-full border-t-primary-700;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top-width: 10px;
                    border-top-style: solid;
                }
            }

            input[type="range"] {
                @apply mt-4 w-full cursor-pointer;

                -webkit-appearance: none;

                &:focus {
                    outline: none;
                }

                &::-webkit-slider-runnable-track {
                    @apply bg-primary-400;
                    height: 5px;
                }

                &::-moz-range-track {
                    @apply bg-primary-400;
                    height: 5px;
                }

                &::-webkit-slider-thumb {
                    @apply bg-primary-700;
                    -webkit-appearance: none;
                    height: 15px;
                    width: 15px;
                    margin-top: -5px;
                    border-radius: 50%;
                }

                &::-moz-range-thumb {
                    height: 15px;
                    width: 15px;
                    @apply bg-primary-700;
                    margin-top: -5px;
                    border-radius: 50%;
                }
            }
        }

        &__breakdown {
            @apply w-full flex-none rounded-lg border border-solid border-grey-200 px-8 py-4 text-center sm:w-44;

            .c-label.medium.light {
                @apply block text-grey-200;
            }

            .c-paragraph {
                @apply my-1;
            }

            .c-button {
                @apply mt-2;
            }
        }

        &-amount {
            @apply font-bold;
        }
    }

    .c-paragraph + .c-personalize-pay__section {
        @apply mt-12;
    }
}
