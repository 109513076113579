.c-paragraph {
    @apply my-0 font-sans text-l font-normal tracking-wide text-grey-500;

    &.mediumn {
        @apply text-base;
    }
    &.large {
        @apply text-2xl-s md:text-2xl;
    }

    &.xlarge {
        @apply text-4xl-s md:text-4xl;
    }

    &.small {
        @apply text-sm;
    }

    &.xsmall {
        @apply text-xs;
    }

    &.semibold {
        @apply font-semibold;
    }

    &.white {
        @apply text-white;
    }

    &.light {
        @apply text-primary-400;
    }

    &.color {
        @apply text-primary-700;
    }

    a {
        @apply text-primary-700 underline;
    }
}
