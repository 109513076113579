.c-personalize-progress {
    @apply flex w-full flex-col items-center justify-between md:flex-row;

    &__progress {
        @apply h-full bg-white;

        &__wrapper {
            @apply h-2.5 w-full flex-none overflow-hidden rounded-full md:w-1/2;

            background-color: rgba(255, 255, 255, 0.25);
        }
    }
}
