h1 {
    @apply font-sans text-4xl-s font-semibold tracking-wide text-primary-700 lg:text-4xl;

    &.light {
        @apply text-primary-400;
    }

    &.white {
        @apply text-white;
    }
}

h2 {
    @apply font-sans text-3xl font-semibold tracking-wide text-primary-700;

    &.light {
        @apply text-primary-400;
    }

    &.white {
        @apply text-white;
    }
}

h3 {
    @apply font-sans text-xl font-semibold tracking-wide text-primary-700;

    &.light {
        @apply text-primary-400;
    }

    &.white {
        @apply text-white;
    }
}

h4 {
    @apply font-sans text-base font-semibold tracking-wide text-primary-700 lg:text-base;

    line-height: 1.25rem;

    &.light {
        @apply text-primary-400;
    }

    &.white {
        @apply text-white;
    }
}
