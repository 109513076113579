.c-confirm {
    @apply mb-16 flex w-full flex-row items-center gap-x-2 rounded-lg bg-primary-400 p-3 text-sm font-semibold text-primary-900;

    svg {
        @apply inline-block;

        width: 20px;
        height: 20px;
    }

    &.errored {
        @apply bg-red-100 text-red-900;
    }
}
