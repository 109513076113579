.c-dropdown {
    &__container {
        .c-dropdown__control {
            @apply mt-1 rounded-lg border border-solid border-grey-200 font-sans text-base text-grey-500 focus:border-primary-700;

            border-width: 1.5px;
            padding-top: 1px;
            padding-bottom: 1px;

            &:focus-visible {
                @apply border-primary-700;

                outline: none;
            }

            &::placeholder {
                @apply text-grey-200;
            }

            &--is-focused {
                @apply shadow-none;
            }

            &--menu-is-open {
                @apply rounded-b-none;
            }
        }

        .c-dropdown__menu {
            @apply my-0 rounded-lg rounded-t-none border border-t-0 border-solid border-grey-200 font-sans text-base text-grey-500 shadow-none;

            border-width: 1.5px;

            &-list {
                @apply py-0;
            }
        }

        .c-dropdown {
            &__option {
                @apply font-sans text-base text-grey-500;

                padding-top: 7px;
                padding-bottom: 7px;

                &--is-selected {
                    @apply bg-white font-bold text-primary-700;
                }

                &--is-focused,
                &:active {
                    @apply bg-primary-200;
                }

                &:last-child {
                    @apply rounded-b-lg;
                }
            }
        }
    }

    &__indicator-separator {
        @apply hidden;
    }

    &__indicator {
        svg {
            @apply text-primary-700;
        }
    }

    &.errored {
        .c-label,
        .c-label__required {
            @apply text-red-900;
        }

        .c-dropdown__container {
            .c-dropdown__control {
                @apply border-red-900 bg-red-100;
            }
        }
    }

    &.search {
        .c-dropdown__container {
            .c-dropdown__control {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &.locked {
        .c-dropdown {
            &__control {
                @apply border-transparent bg-white px-0 text-grey-200;
            }

            &__indicator {
                @apply hidden;
            }

            &__value-container {
                @apply px-0;
            }
        }
    }

    .c-label {
        @apply block;
    }
}
