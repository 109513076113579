.c-custom-scrollbar {
    &__track-vertical {
        @apply absolute rounded-lg bg-primary-400;

        width: 6px;
        right: 0.75rem;
        bottom: 1.25rem;
        top: 1.25rem;
    }

    &__thumb-vertical {
        @apply relative block w-full cursor-pointer rounded-lg bg-primary-700;
    }

    &__view {
        @apply absolute overflow-scroll pr-6;
    }
}
