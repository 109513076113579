.c-personalize-start {
    @apply flex h-full flex-col items-center justify-center text-center;

    &__actions {
        @apply flex flex-row flex-wrap justify-center gap-5;
        margin-top: 3.125rem;

        .c-button {
            @apply flex-none;
        }
    }

    .c-paragraph {
        padding: 0 10%;
    }

    h2 {
        @apply mt-5 mb-2;
    }

    svg {
        @apply text-primary-400;

        height: 40px;
        width: 40px;
    }
}
