.c-procedure-result {
    @apply mx-auto px-4 pb-14 md:px-8 md:pb-0 lg:px-4;
    max-width: 63.5rem;

    > h3 {
        @apply my-6;
    }

    .infinite-scroll-component {
        overflow: hidden !important;
    }

    .c-spinner {
        @apply mx-auto mt-4 mb-8;
    }

    &__header {
        @apply flex w-full flex-row flex-wrap items-center justify-between gap-2;
    }

    &__list {
        @apply w-full;

        &__header,
        &__item {
            @apply flex flex-col px-5 md:flex-row md:items-center;

            > :nth-child(1) {
                @apply flex-1;
            }

            > :nth-child(2) {
                width: 15rem;
            }

            > :nth-child(3) {
                width: 45px;
            }

            .c-label {
                @apply font-semibold tracking-widest text-grey-200;
            }
        }

        &__header {
            @apply mb-1 hidden text-left md:flex;
        }

        &__item {
            @apply mb-2.5 rounded-lg border border-solid border-grey-200 py-4 md:pointer-events-none;
            border-width: 1.5px;

            &__section {
                &.mobile {
                    @apply mt-1 md:hidden;

                    > :nth-child(2) {
                        line-height: 0.45rem;
                    }
                }

                &.desktop {
                    @apply hidden md:block;
                }
            }

            > :nth-child(1) {
                .c-paragraph {
                    @apply font-bold text-grey-700;
                }
            }

            > :nth-child(4) {
                .c-button {
                    @apply pointer-events-auto h-7 w-7 p-0 text-center;

                    svg {
                        @apply inline-block h-4 w-4;
                    }
                }
            }
        }
    }

    &__none {
        @apply mx-auto flex h-1/2 flex-none flex-col items-center justify-center gap-y-2 pb-2 text-center;
        max-width: 40rem;

        svg {
            @apply mb-3 text-primary-400;
            height: 40px;
            width: 40px;
        }
    }

    &.empty {
        @apply flex h-full flex-col px-0 md:px-0 lg:px-0;
        max-width: none;

        .c-category-search {
            @apply flex-1;
        }
    }
}
