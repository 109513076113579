.c-create-account {
    &__form {
        &__actions {
            @apply mt-7 flex flex-row items-center justify-between;
        }

        &__terms {
            @apply mt-7;

            .c-anchor {
                @apply font-normal normal-case;

                display: inline !important;
            }
        }
    }
}
