.c-search {
    @apply w-full;

    &.white {
        input {
            @apply border-transparent;
        }
    }

    &.errored {
        label {
            @apply text-red-900;
        }

        input {
            @apply border-red-900 bg-red-100;
        }
    }

    &.locked {
        input {
            @apply border-transparent bg-white px-0 text-grey-200;
        }
    }

    label {
        @apply block;
    }

    &__wrapper {
        @apply relative mt-1 block rounded-lg border border-grey-300 md:border-0;

        [type="search"] {
            -webkit-appearance: none;
        }

        input {
            @apply h-12 w-full rounded-lg font-sans text-sm text-grey-500 focus:border-primary-700;

            padding-left: 1.875rem;
            padding-right: 5rem;
            height: 3rem;

            &:focus-visible {
                @apply border-primary-700;

                outline: none;
            }

            &::placeholder {
                @apply text-grey-200;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }

            &::-ms-clear,
            &::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }
        }

        max-width: calc(100% - 1rem - 2px);

        svg {
            @apply absolute text-primary-700;

            left: 0.5rem;
            top: calc(50% - 0.625rem);
            width: 1.25rem;
            height: 1.25rem;
        }

        button {
            @apply absolute;

            right: 0.5rem;
            top: calc(50% - 0.8125rem);
            padding: 2px 11px;
        }
    }
}
