.c-create-account-confirmation {
    @apply rounded-lg bg-white p-4 shadow-md md:py-24;

    &__content {
        @apply mx-auto w-full p-4 md:px-24;
    }

    &__header {
        @apply text-center md:px-8;

        > * {
            @apply mb-2;
        }

        svg {
            @apply mb-6 inline-block h-12 w-12 text-primary-400;
        }
    }

    &__actions {
        @apply mt-12 flex flex-col items-center gap-y-2 text-center;

        &.submitted {
            @apply hidden;
        }

        .resend {
            @apply text-xs font-semibold uppercase text-grey-500;

            .c-button {
                @apply p-0 pl-2 underline;
            }
        }
    }
}
