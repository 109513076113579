.c-procedure-detail {
    @apply mb-6 md:mt-6;

    &__cart {
        @apply mb-6 flex w-full flex-row items-center gap-5 rounded-lg bg-primary-700 p-5 text-white;

        &__content {
            @apply flex-1;
        }

        svg {
            @apply h-5 w-5;
        }

        .c-paragraph.small {
            @apply text-sm font-normal;
        }

        .c-paragraph {
            @apply font-semibold text-white;

            + .c-paragraph {
                @apply mt-1;
            }
        }
    }

    &__info {
        @apply my-3 flex flex-col items-start justify-between gap-x-8 gap-y-5 md:flex-row;

        &__code {
            @apply flex flex-col gap-y-1 rounded-lg bg-primary-400 p-5;
        }

        .c-label {
            @apply font-extrabold text-primary-900;
        }

        .c-paragraph.small {
            @apply text-primary-900;

            + .c-label {
                @apply mt-2;
            }
        }

        .c-paragraph {
            @apply flex-1;
        }
    }

    &__locations {
        @apply my-10;

        &__cards {
            @apply mt-3 md:hidden;

            &__action {
                @apply mt-10 mb-5 text-center;
            }

            .c-location {
                @apply border border-solid border-primary-700 bg-transparent;
                border-width: 1.5px;
            }

            .c-procedure-detail {
                &__locations {
                    &__selected {
                        @apply p-0;

                        h4 {
                            @apply text-grey-700;
                        }

                        .c-paragraph {
                            @apply text-grey-700;
                        }
                    }
                }

                &__procedure-list {
                    @apply mt-2;

                    &__item {
                        @apply mb-2.5 block grid grid-cols-2 gap-x-2 px-2.5 py-1.5;

                        > :first-child {
                            @apply col-span-2 p-0;
                        }

                        .c-label {
                            @apply font-semibold tracking-widest text-grey-200;
                        }
                    }
                }
            }
        }

        &__header {
            @apply mb-1;

            svg {
                @apply inline-block h-5 w-5 text-primary-700;
            }

            h3 {
                @apply ml-2 inline-block align-bottom;
            }
        }

        &__list {
            @apply p-5 pb-2 pr-2;

            &__item {
                @apply cursor-pointer;
            }
        }

        &__selected {
            @apply p-5 pr-2;

            h4 {
                @apply mt-4 mb-1 inline-block;
            }

            &__header {
                @apply flex flex-row justify-between;
            }

            .c-tag {
                @apply ml-2 align-middle;

                .c-paragraph {
                    @apply text-xs font-semibold uppercase tracking-wider text-primary-700;
                    line-height: 1.25rem;
                }
            }
        }

        &__wrapper {
            @apply my-6 grid hidden grid-cols-1 rounded-lg border border-solid border-primary-700 md:grid md:grid-cols-3;
            height: 29rem;

            > :nth-child(2) {
                @apply col-span-2 border-l border-solid border-primary-700;
            }
        }
    }

    &__header-button {
        .c-button {
            @screen md {
                @apply border-primary-400 bg-transparent text-xs text-primary-400 hover:opacity-70;
                padding: 5px 20px;
            }
        }
    }

    &__procedure-list {
        @apply mt-5 w-full;

        &__header,
        &__item {
            @apply flex flex-row items-center;

            > :nth-child(1) {
                @apply flex-1 pl-2;
            }

            > :nth-child(2) {
                width: 6rem;
            }

            > :nth-child(3) {
                width: 6rem;
            }
        }

        &__header {
            @apply mb-1 items-end text-left;

            .c-label {
                @apply block font-semibold tracking-widest text-grey-200;
                line-height: 0.75rem;
            }
        }

        &__item {
            @apply mb-2.5 rounded-lg border border-solid border-grey-200 py-1.5;
            border-width: 1.5px;

            &.potential {
                &:last-child {
                    @apply mb-0;
                }
            }
        }
    }

    &__potential-procedures {
        @apply mt-6;
    }
}
