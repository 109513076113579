.c-cart-layout {
    @apply relative h-screen bg-primary-700 md:overflow-hidden;

    &__section {
        @apply h-full flex-1 bg-white;
    }

    &__window {
        @apply flex h-full flex-col md:flex-row;
    }

    &__wrapper {
        @apply bg-white md:h-full md:overflow-y-auto;

        &.short,
        &.footer {
            height: calc(100% - 3.75rem);
        }

        &.short.footer {
            height: calc(100% - 7.5rem);
        }
    }

    &__content {
        @apply relative mx-auto px-4 focus:outline-none md:px-0;

        max-width: 63.5rem;

        &.fullscreen {
            @apply h-full;
        }

        &.fullwidth {
            @apply px-0;

            max-width: none;

            .c-cart-layout {
                &__container {
                    @apply pt-0 md:px-0 lg:px-0;
                }
            }
        }
    }

    &__container {
        @apply clear-both mb-4 h-full px-0 pt-2 md:px-8 lg:px-4;
    }

    &__footer {
        @apply hidden md:block;

        .c-cart-layout__content {
            @apply px-0 md:px-8 lg:px-4;
        }

        &__content {
            @apply flex w-full flex-col items-center justify-center rounded-t-lg bg-primary-900;

            height: 3.75rem;

            .c-button.large {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }

    &__header {
        @apply mt-4 bg-transparent md:mt-0 md:bg-primary-900;

        height: 3.75rem;

        > * {
            padding: 0.625rem 0;
        }

        &__content {
            @apply mx-auto flex h-full flex-row items-center justify-start px-4 md:px-8 lg:px-4;

            max-width: 63.5rem;
        }

        &.locked {
            @apply mt-0 bg-primary-900;
        }

        .c-search {
            max-width: 25rem;

            &__wrapper {
                @apply mt-0;

                max-width: none;

                @screen md {
                    max-width: calc(100% - 1rem - 2px);
                }

                input {
                    @apply h-10;
                }
            }
        }
    }

    &.no-scroll {
        @apply overflow-hidden;
    }

    &.procedure-results-none {
        .c-cart-layout__footer {
            @apply bg-primary-400;
        }
    }
}
