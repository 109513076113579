.c-sign-in {
    @apply h-full;

    &__hero {
        @apply relative flex w-full flex-col bg-primary-700 pb-8 md:flex-row md:pb-0;

        &__background {
            @apply absolute h-full w-full bg-cover bg-center bg-no-repeat md:bg-right;
            background-image: url("../../assets/images/photo-family-fade.jpg");
            top: 0;
            left: 0;

            @screen lg {
                width: 65%;
            }

            @media (min-resolution: 2x), (-webkit-min-device-pixel-ratio: 2) {
                background-image: url("../../assets/images/photo-family-fade@2x.jpg");
            }

            @media (min-resolution: 3x), (-webkit-min-device-pixel-ratio: 3) {
                background-image: url("../../assets/images/photo-family-fade@3x.jpg");
            }
        }

        &__intro {
            @apply flex w-full flex-col justify-between;
            padding: 1.875rem 0 1.875rem 0;

            @screen md {
                width: calc(100% - 23rem);
                padding-right: 1.875rem;
            }

            &.desktop {
                @apply pt-0;

                .c-sign-in__hero__title {
                    @apply hidden;

                    @screen md {
                        @apply block;
                    }
                }
            }

            &.mobile {
                @apply pb-0 md:hidden;
            }
        }

        &__logo {
            @apply flex-none;

            img {
                height: 2rem;
                width: auto;
            }
        }

        &__title {
            @apply flex-none;

            @screen md {
                margin-right: 1.875rem;
            }

            h1 {
                @apply mb-2;
            }

            > .c-label {
                @apply mb-1 block text-white;
            }

            &__more {
                @apply mt-9;

                svg {
                    @apply mr-2 inline-block h-6 w-6 text-primary-400;
                }

                .c-label {
                    @apply inline-block leading-6 text-primary-400;
                }
            }
        }

        &__wrapper {
            @apply relative mx-auto flex h-full flex-col items-stretch p-8 md:flex-row;
            max-width: 63.5rem;
        }
    }

    &__form {
        @apply flex flex-auto flex-col justify-start gap-5 rounded-lg bg-white shadow-md;
        padding: 0.75rem 1.875rem 0.75rem 1.875rem;
        height: 100%;

        * > {
            @apply flex-none;
        }

        &__actions {
            @apply mt-6 flex flex-col items-center;
        }

        .sso-button {
            @apply mb-2 flex;
            flex: 1;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        img {
            height: 1.5rem;
            width: auto;
            @apply ml-auto mb-0 mr-4 mt-8;
            align-self: flex-end;
        }

        form {
            .c-text-input {
                @apply mt-6;

                &.errored {
                    + .c-anchor {
                        @apply mt-6;

                        + .c-sign-in__form__actions {
                            @apply mt-2;
                        }
                    }
                }
            }

            .c-password-strength {
                @apply mt-3;
            }
        }

        .c-anchor {
            @apply block text-grey-200;

            &.forgot-password {
                @apply inline-block py-2 tracking-wider no-underline;
            }
        }

        .c-toggle {
            @apply flex-none;
        }
    }

    &__how {
        @apply pt-7;

        &__item {
            @apply flex flex-col;

            svg {
                @apply mb-3 text-primary-700;
                height: 2.375rem;
                width: 2.375rem;
            }
        }

        &__wrapper {
            @apply mt-10 grid grid-cols-1 gap-12 md:grid-cols-3;
        }
    }

    &__secure {
        svg {
            @apply h-9 w-9 flex-none text-primary-900;
        }

        &__content {
            @apply flex-1;

            .c-label {
                @apply mb-0.5 block font-bold text-primary-900;
            }

            .c-paragraph {
                @apply text-primary-900;
                line-height: 1.125rem;
            }
        }

        &__wrapper {
            @apply flex w-full flex-row items-center gap-4 rounded-lg bg-primary-400 px-4 py-6;
        }
    }

    &__who {
        h3 {
            @apply mb-3 md:col-span-3;
        }

        img {
            @apply h-8 w-auto;

            &.arx {
                @apply h-6;
            }

            + div {
                @apply mt-4;
            }
        }

        .c-paragraph {
            @apply tracking-wider;
        }

        &__wrapper {
            @apply grid grid-cols-1 gap-12 bg-grey-100 p-10 md:grid-cols-3 md:gap-y-4;
        }
    }

    &__footer {
        @apply text-3xs text-grey-200;

        img {
            height: 100%;
            width: auto;
        }

        .c-anchor {
            @apply px-1 text-3xs font-bold tracking-widest text-grey-200;
        }

        .c-paragraph.xsmall {
            @apply text-right font-semibold tracking-widest text-grey-200;
        }

        &__logo {
            @apply h-full;
        }

        &__links {
            flex-grow: 2;
        }

        &__wrapper {
            @apply flex h-8 w-full flex-col items-center justify-between gap-4 text-center md:flex-row;
        }
    }

    &__wrapper {
        @apply relative mx-auto h-full p-8;
        max-width: 63.5rem;
    }
}
