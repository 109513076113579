.c-loader {
    @apply fixed z-10 flex h-full w-full cursor-auto items-center justify-center opacity-0 transition-all;

    top: 0;
    left: 0;

    &.-active {
        @apply opacity-100;
    }

    &__overlay {
        @apply absolute h-full w-full bg-grey-100 opacity-70;
    }
}
