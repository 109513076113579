.c-footer {
    @apply flex flex-col items-center justify-between gap-4 py-6 text-3xs text-grey-200 text-white md:flex-row;

    & > * {
        @apply flex-none;
    }

    &__logo {
        @apply h-full;
    }

    &__links {
        @apply flex-grow text-center;
    }

    .c-anchor {
        @apply px-1 text-3xs font-bold tracking-widest text-grey-200;
    }

    .c-paragraph.xsmall {
        @apply text-right font-semibold tracking-widest text-grey-200;
    }

    img {
        height: 1.25rem;
        width: auto;

        @screen md {
            height: 1.5rem;
        }
    }

    &.colored {
        @apply text-white;

        .c-anchor {
            @apply text-white;
        }

        .c-paragraph.xsmall {
            @apply text-white;
        }
    }

    &.hide-desktop {
        @apply md:hidden;
    }
}
