.c-card {
    @apply inline-block flex flex-row items-start rounded-lg border border-solid border-grey-200 bg-white p-5 pr-1 md:flex-col md:justify-between md:pr-5;

    border-width: 1.5px;
    // min-width: 200px;

    > * {
        @apply my-0;
    }

    button {
        @apply mt-3 hidden h-0 w-full uppercase transition-all md:block md:h-auto;
    }

    h4 {
        @apply text-grey-700;
    }

    svg {
        @apply text-primary-700;

        height: 24px;
        width: 24px;
    }

    &.clickable {
        @apply cursor-pointer;
    }

    &.stacked {
        @apply flex-col pr-5;
    }

    &.white {
        @apply border-transparent;
    }

    &.new-member {
        @apply items-center pt-6 text-center;

        svg {
            @apply inline;

            height: 55px;
            width: 55px;
        }
    }

    &__cart {
        @apply flex h-5 w-10 flex-row items-center justify-between justify-around rounded-full bg-primary-900 px-1 text-white;

        span {
            @apply flex-none font-sans text-2xs;
        }

        svg {
            @apply flex-none text-white;

            height: 12px;
            width: 12px;
        }

        &.icon-only {
            @apply w-7 bg-primary-400;

            svg {
                @apply text-primary-900;

                height: 14px;
                width: 14px;
            }
        }

        &.locked {
            @apply bg-orange-100;

            svg {
                @apply text-orange-900;
            }
        }
    }

    &__content {
        @apply flex w-full flex-1 flex-col;
    }

    &__expand {
        @apply cursor-pointer py-3 pl-2 pr-4 transition-all md:hidden;
    }

    &__icon {
        @apply mt-4 mr-2 flex flex-row items-center justify-between md:mt-0 md:mb-1 md:w-full;

        &.desktop {
            .c-card__cart {
                @apply hidden md:flex;
            }
        }

        &.mobile {
            @apply absolute mr-0 md:hidden;

            right: 68px;
        }
    }

    &.expanded {
        button {
            @apply block h-auto;
        }

        .c-card__expand {
            @apply rotate-180;
        }
    }
}
