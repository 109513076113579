.c-member-form {
    &__actions {
        @apply flex flex-row flex-wrap gap-5;

        &.center {
            @apply justify-center;
        }

        &.right {
            @apply justify-end;
        }

        .c-button {
            @apply flex-none;
        }

        &__total {
            @apply py-[3px] pr-1;
        }
    }

    &__section {
        @apply mt-8;

        &.authorized {
            @apply rounded-lg bg-primary-700 p-6;
        }

        &.center {
            @apply text-center;
        }

        &.right {
            @apply text-right;
        }

        &__wrapper {
            @apply grid gap-y-4 gap-x-8;

            &.double {
                @apply grid-cols-1 md:grid-cols-2;
            }

            &.triple {
                @apply grid-cols-1 md:grid-cols-3;
            }

            &.inline {
                @apply grid grid-cols-1 md:grid-cols-2 gap-4;
            }

            + .c-member-form__section__wrapper {
                @apply mt-4;
            }
        }

        h3 + .c-member-form__section__wrapper {
            @apply mt-4;
        }

        .c-button + .c-button {
            @apply ml-4;
        }
    }
}
