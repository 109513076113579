.c-text-input {
    @apply w-full;

    &.errored {
        .c-label,
        .c-label__required {
            @apply text-red-900;
        }

        input {
            @apply border-red-900 bg-red-100;
        }
    }

    &.locked {
        input {
            @apply border-transparent bg-white px-0 text-grey-200;
        }
    }

    &.password {
        .c-paragraph {
            @apply cursor-pointer uppercase tracking-wider;
        }
    }

    .c-label {
        @apply block;
    }

    input {
        @apply mt-1 w-full rounded-lg border border-solid border-grey-200 px-2 py-1.5 font-sans text-base text-grey-500 focus:border-primary-700;

        border-width: 1.5px;

        &:focus-visible {
            @apply border-primary-700;

            outline: none;
        }

        // max-width: calc(100% - 1rem - 2px);

        &::placeholder {
            @apply text-grey-200;
        }
    }

    &__label {
        &__wrapper {
            @apply flex flex-row justify-between;

            * > {
                @apply flex-none;
            }
        }
    }
}
