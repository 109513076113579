.c-sign-in {
    &__form {
        &__actions {
            @apply mt-6 flex flex-row items-center justify-between;
        }

        .c-sign-in__forgot-password-container {
            display: flex;
            justify-content: flex-end; // Aligns the content to the right
        }
    }

    &__verify-email {
        .c-button {
            @apply m-0 bg-white p-0 pl-5 font-medium text-red-900 underline;
            // line-height: 12px;
            text-transform: none;
        }
    }
}
