.c-text-area {
    @apply w-full;

    &.errored {
        label {
            @apply text-red-900;
        }

        textarea {
            @apply border-red-900 bg-red-100;
        }
    }

    &.locked {
        textarea {
            @apply border-transparent bg-white px-0 text-grey-200;
        }
    }

    label {
        @apply block;
    }

    textarea {
        @apply mt-1 rounded-lg border border-solid border-grey-200 px-2 py-1.5 font-sans text-base text-grey-500 focus:border-primary-700;

        border-width: 1.5px;

        &:focus-visible {
            @apply border-primary-700;

            outline: none;
        }

        max-width: calc(100% - 1rem - 2px);

        &::placeholder {
            @apply text-grey-200;
        }
    }
}
