.c-personalize-summary {
    @apply my-12;

    h2 {
        @apply mb-1;
        font-size: 1.25rem; /* Adjust this value as needed */
        line-height: 1.0; /* Adjust line height if necessary */
    }

    .c-member-form {
        h3 {
            @apply text-grey-700;
        }
        // Override spacing for section wrappers
        &__section {
            @apply mt-2; /* Reduce the top margin for sections */

            &__wrapper {

                &.triple {
                    @apply grid-cols-1 md:grid-cols-3;

                    .c-text-input {
                        input {
                            @apply mt-1 w-full rounded-lg border border-solid border-grey-200 px-1 py-0.5 font-sans text-sm text-grey-500 focus:border-primary-700;
                        }
                    }

                    .c-dropdown {
                        &__container {
                            .c-dropdown__control {
                                @apply mt-1 rounded-lg border border-solid border-grey-200 font-sans text-sm text-grey-500 focus:border-primary-700;
                            }

                            .c-dropdown {
                                &__option {
                                    @apply font-sans text-base text-grey-500;
                                    padding-top: 3px;
                                    padding-bottom: 3px;
                                }
                            }
                        }

                        &.locked {
                            .c-dropdown {
                                &__control {
                                    @apply border-transparent bg-white px-0 text-sm text-grey-500;
                                }

                                &__indicator {
                                    @apply hidden;
                                }

                                &__value-container {
                                    @apply px-0;
                                }
                            }
                        }

                        .c-label {
                            @apply block;
                        }
                    }
                }
            }
        }
    }

    .c-button {

        &.clear {
            @apply border-transparent bg-transparent text-grey-500 hover:opacity-70;
        }
    }
}
