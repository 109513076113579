.c-onboarding-household-page {
    @apply rounded-lg bg-white p-4 shadow-md md:p-12;

    &__header {
        @apply px-2 text-center md:mx-auto md:w-3/4 md:px-8;
    }

    &__household {
        @apply mt-10;

        &__header {
            @apply flex flex-row items-center;

            h2 {
                @apply inline-block;
            }

            .c-button {
                @apply ml-4;
            }
        }

        &__wrapper {
            @apply grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2 md:grid-cols-3;
        }
    }

    &__actions {
        @apply mt-10 text-center;
    }
}
