.c-location {
    @apply mb-3 inline-block w-full rounded-lg bg-primary-400 p-2 transition;

    &__content {
        @apply flex-auto;

        &__wrapper {
            @apply flex flex-row items-center;

            .c-button.primary {
                @apply flex-none;

                padding: 4px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .c-paragraph.small {
            @apply text-primary-700;
        }

        .c-paragraph.facilityName {
            @apply font-bold;
        }

        .c-paragraph {
            @apply my-0 tracking-wider text-primary-700 transition;

            + .c-paragraph {
                @apply mt-2;
            }
        }
    }

    &__tags {
        @apply mt-2 flex w-full flex-row flex-wrap justify-between gap-2;
    }

    .c-tag {
        @apply bg-primary-700;

        .c-paragraph {
            @apply text-primary-400;
        }
    }

    &.white {
        @apply rounded-lg border border-solid border-grey-200 bg-white;

        .c-paragraph {
            @apply text-grey-700;
        }

        .c-tag {
            @apply bg-grey-200;

            .c-paragraph {
                @apply text-white;
            }
        }
    }

    &.active {
        @apply bg-primary-700;

        .c-paragraph {
            @apply text-white;
        }

        .c-tag {
            @apply bg-primary-400;

            .c-paragraph {
                @apply text-primary-700;
            }
        }
    }

    &.card {
        @apply flex flex-col p-5;

        .c-location__children {
            @apply max-h-0 overflow-hidden transition-all;
        }

        .c-tag {
            @apply bg-primary-400;

            .c-paragraph {
                @apply text-primary-700;
            }
        }
    }

    &.expanded {
        .c-location {
            &__content {
                &__wrapper {
                    .c-button.primary {
                        @apply rotate-180;
                    }
                }
            }

            &__children {
                max-height: 200rem;
            }
        }
    }
}
