.c-toggle {
    @apply relative h-8 cursor-pointer rounded-full border border-solid border-primary-400;

    border-width: 1.5px;
    z-index: 0;

    &__option {
        @apply inline-block w-1/2 text-center text-xs font-semibold uppercase tracking-wider text-grey-200;

        line-height: 30px;

        &.selected {
            @apply text-white;
        }
    }

    &__overlay {
        @apply absolute h-full w-1/2 rounded-full bg-primary-700 transition-all;

        top: 0.5px;
        left: 0.5px;
        width: calc(50% - 1px);
        height: calc(100% - 1px);
        z-index: -1;

        // &.left {
        //     left: 0.5px;
        // }

        &.right {
            left: calc(50% + 0.5px);
        }
    }
}
