.c-password-strength {
    @apply rounded-lg bg-grey-100 p-2 text-xs font-semibold;

    .c-paragraph {
        @apply inline-block font-medium;
    }

    &__list {
        &__item {
            @apply mb-0.5;

            &.indent {
                @apply ml-4;
            }

            &__icon {
                @apply mr-1.5 inline-block align-middle;

                width: 16px;
                height: 16px;

                &.empty {
                    @apply rounded-full border-2 border-solid border-grey-200;

                    margin-left: 1px;
                    margin-right: 7px;
                    width: 14px;
                    height: 14px;
                }

                &.invalid {
                    @apply text-red-900;
                }

                &.valid {
                    @apply text-green-900;
                }
            }
        }
    }
}
