.c-button {
    @apply inline-block cursor-pointer items-center rounded-full border-2 border-solid border-transparent bg-primary-700 font-sans text-xs font-semibold uppercase tracking-wider text-white transition-all hover:bg-primary-600;

    padding: 5px 20px;

    &.dark {
        @apply bg-primary-900 hover:bg-primary-800;
    }

    &.clear {
        @apply border-grey-200 bg-transparent text-grey-500 hover:opacity-70;
    }

    &.dark-gray {
        @apply bg-grey-500 hover:bg-grey-400;
    }

    &.gray {
        @apply bg-grey-200 hover:bg-grey-300;
    }

    &.light {
        @apply border-primary-400 bg-transparent text-primary-400 hover:opacity-70;
    }

    &.white {
        @apply border-primary-700 bg-white text-primary-700 hover:bg-grey-100;
    }

    &.plain {
        @apply border-none bg-transparent text-grey-500 hover:opacity-70;
    }

    &.small {
        @apply text-3xs;

        padding: 4px 10px;
    }

    &.large {
        @apply text-sm;

        padding-top: 7px;
        padding-bottom: 7px;
    }

    &.shopping-cart {
        @apply flex h-12 flex-row items-center justify-around px-2 py-0;

        height: 2.25rem;
        width: 3.875rem;

        svg {
            @apply inline-block flex-none;

            height: 20px;
            width: 20px;
        }

        span {
            @apply flex-none align-top text-l;
        }

        &.clear {
            @apply border-primary-700 text-primary-700;
        }

        &.white {
            @apply border-white bg-transparent text-white hover:bg-primary-600;
        }

        &.small {
            @screen md {
                height: 1.25rem;
                width: 2.5rem;
            }

            span {
                @apply text-xs;
            }

            svg {
                height: 12px;
                width: 12px;
            }
        }

        &.has-count {
            span {
                @apply text-2xs;
            }
        }
    }

    &.icon {
        @apply pl-3;

        svg {
            @apply mr-2 inline-block;

            height: 20px;
            width: 20px;
        }
    }

    &[disabled] {
        @apply cursor-default bg-grey-400;
    }
}
