.c-tag {
    @apply inline-block h-5 rounded-full bg-primary-400 px-2 transition;

    .c-paragraph {
        @apply text-3xs font-semibold uppercase tracking-wider text-primary-700;
        line-height: 1.25rem;
    }

    svg {
        @apply mr-0.5 inline h-3 w-auto;
    }

    }
