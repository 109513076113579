.c-payment {
    &__section {
        &__summary {
            @apply my-8 flex flex-col gap-x-10 gap-y-4 md:flex-row;

            &__payment-details {
                @apply rounded-lg bg-primary-700 p-4 text-white;

                h4 {
                    @apply font-normal text-white;
                }

                p.c-paragraph.xlarge {
                    @apply font-light md:text-4xl-s;
                }

                button.c-button.small {
                    @apply mt-6 inline-block p-0 tracking-widest text-primary-400 underline;
                }
            }

            &__item {
                @apply flex-grow rounded-lg border border-solid border-grey-200 p-4;
            }
        }
    }
}
