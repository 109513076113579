.c-manage-payment-modal {
    @apply fixed inset-0 z-10 w-full overflow-y-auto;

    &__content {
        @apply flex min-h-screen items-center justify-center py-8 px-0 text-center md:block md:p-0 md:px-4;
    }

    &__header {
        @apply mb-4 inline-block w-full align-top;

        button.c-button.medium {
            @apply p-0 text-sm;

            svg {
                @apply inline-block;

                height: 20px;
                width: 20px;
            }
        }
    }

    &__overlay {
        @apply fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity;
    }

    &__wrapper {
        @apply m-4 inline-block w-full max-w-3xl transform rounded-lg bg-white p-10 text-left align-bottom shadow-xl transition-all md:w-3/4 md:align-middle;
    }

    &__actions {
        @apply mt-14;
    }
}
