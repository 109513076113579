.c-procedure-banner {
    @apply mb-4 mt-6 flex min-h-[56px] justify-center;

    &__wrapper {
        @apply flex w-full flex-row items-center justify-between gap-x-2 rounded-lg bg-primary-400 p-3 text-sm font-semibold text-primary-900;

        &.--expired {
            @apply bg-red-200 text-red-900;
        }
    }

    &__content {
        @apply flex items-center space-x-2;
    }

    &__tag-icon {
        @apply inline-block h-[20px] w-[20px] flex-shrink-0;
    }
}
