.c-anchor {
    @apply my-0 font-sans text-base font-semibold uppercase tracking-wider text-grey-500 underline;

    &.large {
        @apply text-3xl-s lg:text-3xl;

        @screen lg {
        }
    }

    &.small {
        @apply text-sm;
    }

    &.xsmall {
        @apply text-xs;
    }

    &.plain {
        @apply no-underline;
    }

    &.color {
        @apply text-primary-700;
    }

    &.primary-button {
        @apply inline-block cursor-pointer items-center rounded-full border-2 border-solid border-transparent bg-primary-700 font-sans text-xs font-semibold uppercase tracking-wider text-white no-underline transition-all hover:bg-primary-600;

        &.large {
            @apply py-[8px] px-[33px] text-sm;
        }
    }
}
