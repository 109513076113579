.c-sidebar {
    @apply flex flex-none flex-col pb-3 md:pb-0;

    @screen md {
        width: 11.25rem;
    }

    @screen lg {
        width: 15rem;
    }

    &__wrapper {
        @apply flex h-full flex-col overflow-auto bg-primary-700 md:gap-y-5;

        @screen md {
            padding-top: 1.875rem;
        }

        > * {
            @apply flex-initial;

            padding: 0.5rem 1.875rem;

            @screen md {
                padding: 0;
            }

            &:last-child {
                @apply mb-0;
            }
        }
    }

    button.light {
        @apply text-3xs md:text-xs;
        padding: 4px 10px;
    }

    &__expand {
        @apply inline-block;

        padding: 1rem 1.875rem 1rem 0.5rem;

        svg {
            @apply transition-all;
        }
    }

    &__intro {
        @apply flex w-full flex-row items-center justify-between p-0;

        padding-left: 1.875rem;
        padding-right: 1.875rem;

        &__buttons {
            @apply flex flex-none flex-row items-center md:hidden;

            .c-button.shopping-cart {
                @apply h-5 w-10 px-1 py-1;
            }
        }

        &__content {
            @apply flex flex-row items-center;

            .c-label {
                @apply ml-2.5 flex-auto text-white;
            }
        }

        svg {
            @apply flex-none text-white;

            width: 20px;
            height: 20px;
        }
    }

    &__dashboard {
        @apply p-0;

        @screen md {
            padding-left: 1.875rem;
            padding-right: 1.875rem;
        }

        > * {
            @apply inline-block;

            padding: 0.5rem 1.875rem;

            @screen md {
                padding: 0;
            }
        }
    }

    &__header {
        @apply flex w-full flex-row items-center justify-between;

        padding: 1.875rem 1.875rem 0.5rem;

        @screen md {
            padding-top: 0;
        }
    }

    &__logo {
        width: 50%;

        @screen md {
            width: 90%;
        }

        img {
            @apply w-full;
        }
    }

    &__item {
        @apply rounded-lg bg-primary-900 py-2 px-3;

        &__footer {
            @apply mt-3 flex flex-row flex-wrap items-center justify-between gap-2;

            button.shopping-cart {
                @apply flex-none;

                span {
                    line-height: 0.625rem;
                }
            }
        }

        &__wrapper {
            @apply hidden flex-1 overflow-y-auto md:block;

            @screen md {
                padding-left: 1.875rem;
                padding-right: 1.875rem;
                padding-bottom: 1.875rem;
            }
        }

        &:not(.locked):not(.none) {
            @apply cursor-pointer;
        }

        p {
            @apply font-medium text-white;

            &.facility {
                @apply text-primary-400;
            }

            &.price {
                @apply flex-none;
            }
        }

        + .c-sidebar__item {
            @apply mt-3;
        }
    }

    &__personalized-price {
        @apply mt-2 w-full text-center md:hidden;

        padding: 0 1.875rem;

        .c-button {
            @apply w-full;
        }
    }

    &__powered {
        @apply w-1/2;

        &.desktop {
            @apply hidden md:block;
        }

        &.mobile {
            @apply mt-7 mb-5 hidden w-auto;

            padding: 0 1.875rem;

            img {
                @apply h-5;
            }
        }
    }

    &__signout {
        @apply flex flex-none cursor-pointer flex-row items-center text-center;

        height: 2.375rem;

        &.header {
            @apply md:hidden;

            .c-sidebar__signout__link {
                padding: 0.25rem 0 0.25 1.875rem;
            }
        }

        &.footer {
            @apply hidden bg-primary-999 md:flex;

            .c-sidebar__signout__link {
                padding: 0.5rem 1.875rem;
            }
        }

        .c-label {
            @apply ml-1 cursor-pointer text-primary-400;

            line-height: 1.5rem;
        }

        svg {
            @apply inline-block h-6 w-6 text-primary-400;
        }
    }

    &__subtotal {
        @apply hidden md:block md:bg-primary-900;

        padding: 1.25rem 1.875rem;

        .c-sidebar__powered {
            @apply mt-5;
        }
    }

    &.expanded {
        @apply h-full overflow-scroll;

        .c-sidebar {
            &__expand {
                svg {
                    @apply rotate-180;
                }
            }

            &__item {
                &__wrapper {
                    @apply block;
                }
            }

            &__powered.mobile {
                @apply block;
            }

            &__subtotal {
                @apply block;
            }
        }
    }
}
