.c-spinner {
    @apply h-32 w-32 rounded-full border-4 border-solid;
    top: calc(50% - 64px);
    left: calc(50% - 64px);

    &.primary {
        @apply animate-spin  border-primary-700;
        border-top-color: transparent;
    }

    &.secondary {
        @apply animate-spin border-white;
        border-top-color: transparent;
    }

    &.small {
        @apply h-16 w-16;
    }
}
