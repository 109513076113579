.c-order-list {
    &__header {
        @apply my-4
            flex
            w-full
            items-center
            justify-between
            pr-4;

        &-item {
            @apply whitespace-nowrap
                text-3xs
                font-bold
                uppercase
                tracking-[1.5px]
                text-grey-200;
        }
    }
}
