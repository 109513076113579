.c-procedure-search {
    @apply flex flex-col items-center pb-8 md:h-full;

    &__confirm {
        .c-member-form__section__wrapper + .c-member-form__section__wrapper {
            @apply my-12;
        }
    }

    &__content {
        @apply mx-auto flex flex-none flex-col items-center justify-center gap-y-4 px-4 text-center md:px-8 lg:px-4;

        max-width: 63.5rem;

        &__links {
            .c-button {
                @apply mb-4 text-primary-400 underline;
            }
        }

        &__wrapper {
            @apply w-full bg-primary-900 py-12;
        }

        .c-search {
            @apply mb-2 w-full md:w-3/4 md:px-8;

            &__wrapper {
                max-width: none;

                @screen md {
                    max-width: calc(100% - 1rem - 2px);
                }
            }
        }
    }

    &__cta {
        @apply flex w-full flex-col items-center gap-4 rounded-lg bg-primary-400 px-5 py-4 md:flex-row;

        .c-button {
            @apply whitespace-nowrap md:ml-12;
        }

        svg {
            @apply text-primary-700;

            height: 1.375rem;
            width: 1.375rem;
        }

        &__wrapper {
            @apply px-4 md:px-8 lg:px-4;

            max-width: 63.5rem;
        }
    }

    &__cpt-code {
        &__close {
            @apply absolute cursor-pointer p-4;

            top: 0.25rem;
            left: 0.25rem;

            svg {
                @apply mr-1 inline-block;
            }
        }

        &__content {
            @apply mt-8 grid grid-cols-1 gap-4 md:grid-cols-3;
        }

        &__header {
            @apply mt-1;
        }

        &__section {
            svg {
                @apply mb-4 text-primary-700;
            }
        }

        .c-paragraph.medium {
            @apply text-center font-normal;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }
}
