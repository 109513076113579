.c-label {
    @apply font-sans text-3xs font-semibold uppercase tracking-widest text-grey-500;

    &.large {
        @apply text-sm font-bold tracking-wider;
    }

    &.light {
        @apply text-primary-400;
    }

    &__required {
        @apply text-primary-700;
    }
}
