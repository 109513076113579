.c-dashboard-layout {
    @apply relative flex h-screen overflow-hidden;

    &__window {
        @apply flex w-0 flex-1 flex-col overflow-y-auto;
    }

    &__content {
        @apply relative mx-auto w-full flex-1 px-4 focus:outline-none md:px-0;

        max-width: 63.5rem;
    }

    &__container {
        @apply flex flex-col justify-between px-0 pt-2 md:px-8 lg:px-4;

        min-height: calc(100vh - 72px);

        &__actions {
            @apply flex flex-row items-center space-x-4;

            img {
                @apply hidden md:ml-14 md:block lg:ml-6;
            }

            &__logout {
                @apply cursor-pointer;

                .c-label {
                    @apply ml-1 cursor-pointer text-primary-400;

                    line-height: 1.5rem;
                }

                svg {
                    @apply inline-block h-6 w-6 text-primary-400;
                }
            }
        }

        &__header {
            @apply bg-primary-700 py-5;

            &__content {
                @apply flex flex-row justify-between px-0 md:px-8 lg:px-4;
            }

            img {
                height: 2rem;
                width: auto;

                + img {
                    height: 1.5rem;
                }
            }
        }
    }

    &.colored {
        @apply bg-primary-700;
    }
}
