.c-dashboard {
    @apply mt-8 w-full;

    &__header {
    }

    &__household {
        @apply mt-2;

        &__header {
            @apply flex flex-row items-center;

            h2 {
                @apply inline-block;
            }

            .c-button {
                @apply ml-4;
            }
        }

        &__wrapper {
            @apply mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2;
        }

        .c-card {
            @apply border-none;
        }
    }

    &__payments {
        &__wrapper {
        }
    }

    &__wrapper {
        @apply grid grid-cols-1 gap-y-4 gap-x-8 md:grid-cols-2;
    }
}
